<template>
  <product title="Amazfit 米动手表青春版 Lite"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="299"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/TnpjKh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'bip-lite',
  components: {
    Product
  },
  data () {
    return {
      slogan: '续航 45 天 | 32 克无感佩戴 | 全天心率 | 支付宝离线支付',
      colors: [
        '曜石黑',
        '深海蓝',
        '藕荷粉'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/bip-lite/swiper/1.png',
          CDN_URL + '/images/product/bip-lite/swiper/2.png',
          CDN_URL + '/images/product/bip-lite/swiper/3.png',
          CDN_URL + '/images/product/bip-lite/swiper/10.png'
        ],
        [
          CDN_URL + '/images/product/bip-lite/swiper/4.png',
          CDN_URL + '/images/product/bip-lite/swiper/5.png',
          CDN_URL + '/images/product/bip-lite/swiper/6.png',
          CDN_URL + '/images/product/bip-lite/swiper/11.png'
        ],
        [
          CDN_URL + '/images/product/bip-lite/swiper/7.png',
          CDN_URL + '/images/product/bip-lite/swiper/8.png',
          CDN_URL + '/images/product/bip-lite/swiper/9.png',
          CDN_URL + '/images/product/bip-lite/swiper/12.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/bip-lite/detail/01w.jpg',
        CDN_URL + '/images/product/bip-lite/detail/1_2.jpg',
        CDN_URL + '/images/product/bip-lite/detail/1_3.jpg',
        CDN_URL + '/images/product/bip-lite/detail/1_4.jpg',
        CDN_URL + '/images/product/bip-lite/detail/1_5.jpg',
        CDN_URL + '/images/product/bip-lite/detail/1_6.jpg',
        CDN_URL + '/images/common/zepp-white.jpg',
        CDN_URL + '/images/product/bip-lite/detail/1_7.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/bip-lite/detail/08_1.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
